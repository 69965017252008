<template>
    <div
        v-loading="$wait.is('loading.email_content')"
        class="card flex-1"
        element-loading-spinner="el-custom-spinner"
    >
        <header class="flex justify-between items-center px-4 py-2">
            <p>{{ emailData.subject }}</p>
            <div>
                <el-button type="danger" :disabled="!userCan('delete emails')" @click="deleteEmail">
                    {{ $t('communication.delete') }}
                </el-button>
                <el-button type="primary" @click="goBack">
                    {{ $t('common.go_back') }}
                </el-button>
            </div>
        </header>

        <header class="flex justify-between items-center border-b border-solid border-t border-gray-300 p-4 mb-5">
            <div class="flex items-center">
                <router-link v-if="emailData.client_uuid" :to="`/clients/${emailData.client_uuid}/dashboard`">
                    <div class="flex w-10 h-10 justify-center items-center border rounded-full mr-2">
                        <fa-icon :icon="['fas', 'user']" class="text-gray-600 mb-1" />
                    </div>
                </router-link>
                <div v-else class="flex w-10 h-10 justify-center items-center border rounded-full mr-2">
                    <fa-icon :icon="['fas', 'user']" class="text-gray-600 mb-1" />
                </div>
                <div class="flex flex-col">
                    <router-link v-if="emailData.client_uuid" :to="`/clients/${emailData.client_uuid}/dashboard`" class="">
                        {{ emailData.sender_name }}
                    </router-link>
                    <p v-else>
                        {{ emailData.sender_name }}
                    </p>
                    <p class="text-xs text-gray-400 hover:text-gray-600 font-hairline transition">
                        {{ $t('communication.from') }}: {{ emailData.sender_email }}
                    </p>
                </div>
            </div>
            <div class="flex flex-col">
                <p class="text-xs text-right">
                    {{ emailData.created_at }}
                </p>
                <p class="text-xs text-gray-400 hover:text-gray-600 font-hairline transition">
                    {{ $t('communication.to') }}: {{ emailData.receiver_email }}
                </p>
            </div>
        </header>
        <div class="px-4" v-html="emailData.html" />
    </div>
</template>
<script>
export default {
    props: {
        selectedEmailUuid: {
            type:    String,
            default: '',
        },
    },

    data() {
        return {
            emailData: {},
        };
    },

    watch: {
        selectedEmailUuid() {
            if (!this.selectedEmailUuid) return;
            this.getEmailDetails();
        },
    },

    methods: {
        async getEmailDetails() {
            this.$wait.start('loading.email_content');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/communication/emails/${this.selectedEmailUuid}`);
            this.emailData = data;
            this.$wait.end('loading.email_content');
        },

        goBack() {
            this.emailData = [];
            this.$emit('goBack');
        },

        deleteEmail() {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        // @Filip
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;

                        try {
                            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/communication/emails/${this.selectedEmailUuid}`);
                            this.$notify.success({
                                title: 'Success',
                            });

                            this.emailData = [];
                            this.$emit('goBack');
                        } catch {
                            return;
                        }

                        done();
                        // @Filip
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                    } else {
                        done();
                    }
                },
            });
        },
    },
};
</script>
